<template>
  <section class="login-section">
    <div class="center-page">
      <div>
        <img src="/images/msf_login_logo.svg" alt="msf_login_logo" />
      </div>
      <div class="login-input-wrapper">
        <input-box
          type="email"
          id="login-id"
          v-model="userId"
          placeholder="이메일 아이디 입력"
          autocomplete="off"
          :errorMessage="idErrorMessage"
          class="mb-10"
          is-height-large
        />
        <input-box
          type="password"
          id="login-password"
          v-model="userPassword"
          placeholder="비밀번호 입력"
          autocomplete="off"
          :errorMessage="pwErrorMessage"
          @keyupenter="onKeyupEnter"
          is-height-large
        />
        <button-basic color="blue" width="100%" height="40" :loading-dot="isLoadingLogin" @click="onClickLogin">
          {{ $t('message.login_btn') }}
        </button-basic>
        <div class="bottom-btn-wrapper">
          <checkbox-basic color="white" v-model="saveLoginId" value="true">아이디 저장</checkbox-basic>
          <button class="find-password" @click="togglePopup('FindPassword')">비밀번호 찾기</button>
        </div>
      </div>
    </div>
    <confirm-popup :isShow="showedPopupName === 'ConfirmPopup'" :contentsKey="popupContentsKey" @close="togglePopup" />
    <find-password-popup :isShow="showedPopupName === 'FindPassword'" @close="togglePopup" />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import validEmail from '@/asset/js/validEmail.js';
import visiblePassword from '@/asset/js/visiblePassword';
import InputBox from '@/component/input/InputBox';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import FindPasswordPopup from '@/component/popup/findpasswordPopup/FindPasswordPopup';
import isNotChromeBrowser from '@/asset/js/isNotChromeBrowser';

export default {
  name: 'Login',
  data() {
    return {
      userId: '',
      userPassword: '',
      idErrorMessage: '',
      pwErrorMessage: '',
      isLoadingLogin: false,
      saveLoginId: [],
      showedPopupName: '',
      popupContentsKey: '',
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapState('auth', ['nextPage']),
    ...mapGetters('users', ['isMember']),
  },
  created() {
    this.userId = localStorage.getItem('loginId');
    if (this.userId) {
      this.saveLoginId = ['true'];
      localStorage.setItem('loginId', this.userId);
    }
    this.INIT_TOKENS();
    this.INIT_MY_INFO();
  },
  watch: {},
  methods: {
    ...mapMutations('users', ['SET_MY_CURRENT_COMPANY', 'INIT_MY_INFO']),
    ...mapMutations('globalError', ['SET_ERROR_POPUP']),
    ...mapMutations('auth', ['INIT_TOKENS', 'SET_NEXT_PAGE', 'INIT_NEXT_PAGE']),
    ...mapActions('adminConfigs', ['GET_ADMIN_CONFIGS']),
    ...mapActions('auth', ['POST_REQUEST_TOKEN']),
    ...mapActions('users', ['GET_MY_INFO']),
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    initErrorMessage() {
      this.idErrorMessage = '';
      this.pwErrorMessage = '';
    },
    checkError() {
      this.initErrorMessage();
      let isError = false;

      if (!this.userId) {
        this.idErrorMessage = '아이디를 입력하세요.';
        isError = true;
      } else if (!validEmail(this.userId)) {
        this.idErrorMessage = '아이디는 이메일 형식이어야 합니다.';
        isError = true;
      }

      if (!this.userPassword) {
        this.pwErrorMessage = '비밀번호를 입력하세요.';
        isError = true;
      }

      return isError;
    },
    saveIsLoginId() {
      if (this.saveLoginId[0] === 'true') {
        localStorage.setItem('loginId', this.userId);
      } else {
        localStorage.removeItem('loginId');
      }
    },
    async onClickLogin() {
      if (isNotChromeBrowser()) {
        this.SET_ERROR_POPUP('recommend_chrome');
      } else if (!this.checkError()) {
        try {
          this.saveIsLoginId();
          this.isLoadingLogin = true;
          await this.requestToken();
          const myInfo = await this.GET_MY_INFO();
          if (this.isMember) {
            this.togglePopup('ConfirmPopup', 'LOGIN_ROLE_WARN');
            this.isLoadingLogin = false;
            this.INIT_MY_INFO();
          } else {
            if (!this.nextPage) {
              this.SET_MY_CURRENT_COMPANY(myInfo['roleList']);
            }
            const nextPage = this.nextPage || this.setRoleByNextPage(this.currentCompany, myInfo['roleList']);
            this.$router.push(nextPage).catch((err) => err);
            sessionStorage.removeItem('nextPage');
            this.INIT_NEXT_PAGE();
          }
          await this.GET_ADMIN_CONFIGS();
        } finally {
          this.isLoadingLogin = false;
        }
      }
    },
    async requestToken() {
      try {
        const userData = {
          loginId: this.userId,
          pw: this.userPassword,
        };
        await this.POST_REQUEST_TOKEN(userData);
      } catch (error) {
        this.pwErrorMessage = '아이디 또는 비밀번호를 확인하세요.';
        this.idErrorMessage = ' ';
        return Promise.reject(error);
      }
    },
    setRoleByNextPage(currentCompany, roleList) {
      switch (currentCompany) {
        case 'M':
          return roleList.includes('SUPERVISOR') ? '/users' : '/equipments';
        case 'F':
          return '/equipment-groups';
        default:
          return '/role-selection';
      }
    },
    onKeyupEnter() {
      if (!this.isLoadingLogin) {
        this.onClickLogin();
      }
    },
    onChangeVisibility(e, pw) {
      visiblePassword(e, pw);
    },
  },
  components: { ConfirmPopup, InputBox, FindPasswordPopup },
};
</script>

<style scoped lang="scss">
@import 'Login';
</style>
