<template>
  <div class="find-password-popup-wrapper">
    <general-simple-popup v-if="isShow">
      <h2 slot="title">비밀번호 찾기</h2>
      <div slot="contents">
        <p class="mb-40">등록된 이메일 계정으로 비밀번호 재설정 메일이 발송됩니다.</p>
        <input-box
          id="email"
          v-model="email"
          autocomplete="off"
          name="mail"
          placeholder="이메일 아이디"
          class="mb-40"
          @input="onCheckValidity"
          is-height-large
        />
      </div>
      <button-basic slot="button" color="gray-border-1" @click="onClosePopup">
        {{ $t('message.cancel_btn') }}
      </button-basic>
      <button-basic slot="button" color="dark" @click="onSendEmail" :loading-dot="isLoading" :disabled="!isValidEmail">
        {{ $t('message.confirm_btn') }}
      </button-basic>
    </general-simple-popup>
    <confirm-popup :isShow="showedPopupName === 'ConfirmPopup'" :contentsKey="popupContentsKey" @close="togglePopup" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';
import InputBox from '@/component/input/InputBox';
import validEmail from '@/asset/js/validEmail';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'FindPasswordPopup',
  props: ['isShow'],
  data() {
    return {
      email: '',
      isValidEmail: false,
      showedPopupName: '',
      popupContentsKey: '',
      isLoading: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['GET_RESET_PASSWORD']),
    async onSendEmail() {
      try {
        this.isLoading = true;
        await this.GET_RESET_PASSWORD(this.email);
        this.togglePopup('ConfirmPopup', 'RESET_PASSWORD_EMAIL');
        this.email = '';
      } finally {
        this.isLoading = false;
        this.$emit('close');
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onCheckValidity(value) {
      this.isValidEmail = !!validEmail(value);
    },
    onClosePopup() {
      this.email = '';
      this.$emit('close');
    },
  },
  components: { InputBox, GeneralSimplePopup, ConfirmPopup },
};
</script>

<style scoped lang="scss"></style>
