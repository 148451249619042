var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "login-section" },
    [
      _c("div", { staticClass: "center-page" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "login-input-wrapper" },
          [
            _c("input-box", {
              staticClass: "mb-10",
              attrs: {
                type: "email",
                id: "login-id",
                placeholder: "이메일 아이디 입력",
                autocomplete: "off",
                errorMessage: _vm.idErrorMessage,
                "is-height-large": "",
              },
              model: {
                value: _vm.userId,
                callback: function ($$v) {
                  _vm.userId = $$v
                },
                expression: "userId",
              },
            }),
            _c("input-box", {
              attrs: {
                type: "password",
                id: "login-password",
                placeholder: "비밀번호 입력",
                autocomplete: "off",
                errorMessage: _vm.pwErrorMessage,
                "is-height-large": "",
              },
              on: { keyupenter: _vm.onKeyupEnter },
              model: {
                value: _vm.userPassword,
                callback: function ($$v) {
                  _vm.userPassword = $$v
                },
                expression: "userPassword",
              },
            }),
            _c(
              "button-basic",
              {
                attrs: {
                  color: "blue",
                  width: "100%",
                  height: "40",
                  "loading-dot": _vm.isLoadingLogin,
                },
                on: { click: _vm.onClickLogin },
              },
              [_vm._v(" " + _vm._s(_vm.$t("message.login_btn")) + " ")]
            ),
            _c(
              "div",
              { staticClass: "bottom-btn-wrapper" },
              [
                _c(
                  "checkbox-basic",
                  {
                    attrs: { color: "white", value: "true" },
                    model: {
                      value: _vm.saveLoginId,
                      callback: function ($$v) {
                        _vm.saveLoginId = $$v
                      },
                      expression: "saveLoginId",
                    },
                  },
                  [_vm._v("아이디 저장")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "find-password",
                    on: {
                      click: function ($event) {
                        return _vm.togglePopup("FindPassword")
                      },
                    },
                  },
                  [_vm._v("비밀번호 찾기")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.togglePopup },
      }),
      _c("find-password-popup", {
        attrs: { isShow: _vm.showedPopupName === "FindPassword" },
        on: { close: _vm.togglePopup },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/msf_login_logo.svg", alt: "msf_login_logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }